/* stylelint-disable selector-class-pattern */

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  margin: 0;
}

/*
 We could replace all form-groups with this, but it also is used in javascript selectors a lot.
 Removing .form-group turned out to be a headache
 */
.form-group {
  @extend .row;
  @extend .g-3;
  @extend .mb-3;
}

.g-recaptcha {
  display: inline-block;

  //margin-right: -2px;
}

.form-inline {
  .select2-container.form-control {
    display: inline-block;

    min-width: 100px;
  }
}

.Select__control {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

// I had to use `div` here to ensure it was more specific than the generated .css-<hash>-control
div.Select__menu {
  z-index: 3;
}

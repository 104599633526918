@import "~@comanage/bootstrap/variables";

//:root {
//  --breakpoint-xs: 0;
//  --breakpoint-sm: 576px;
//  --breakpoint-md: 768px;
//  --breakpoint-lg: 992px;
//  --breakpoint-xl: 1200px;
//  --font-family-sans-serif: Montserrat, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
//  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
//}

$min-contrast-ratio: 2;

// cause fuck consistency, right?
$enable-rounded: true;

$blue: #7832e2;
$indigo: #502c6c;
$purple: #2d2460;
$pink: #ff0266;
$red: #ff5916;
$orange: #fbb500;
$yellow: #ffde03;
$green: #44c553;
$teal: #09ebaf;
$cyan: #35bdff;
$white: #ffffff;
$gray: #6c757d;
$gray-dark: #343a40;
$primary: #f46a54;
$secondary: #d3bbab;
$success: #28a745;
$info: #00b3ff;
$warning: #f39c12;
$danger: #ec321f;
$light: #f8f9fa;
$dark: #080808;
$tertiary: #fbf7f5;
$light-blue: #e4effc;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "tertiary": $tertiary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "white": $white,
);

$all-colors: map-merge(
  (
    gray-100: $gray-100,
    gray-200: $gray-200,
    gray-300: $gray-300,
    gray-400: $gray-400,
    gray-500: $gray-500,
    gray-600: $gray-600,
    gray-700: $gray-700,
    gray-800: $gray-800,
    gray-900: $gray-900,
    blue-100: $blue-100,
    blue-200: $blue-200,
    blue-300: $blue-300,
    blue-400: $blue-400,
    blue-500: $blue-500,
    blue-600: $blue-600,
    blue-700: $blue-700,
    blue-800: $blue-800,
    blue-900: $blue-900,
    indigo-100: $indigo-100,
    indigo-200: $indigo-200,
    indigo-300: $indigo-300,
    indigo-400: $indigo-400,
    indigo-500: $indigo-500,
    indigo-600: $indigo-600,
    indigo-700: $indigo-700,
    indigo-800: $indigo-800,
    indigo-900: $indigo-900,
    purple-100: $purple-100,
    purple-200: $purple-200,
    purple-300: $purple-300,
    purple-400: $purple-400,
    purple-500: $purple-500,
    purple-600: $purple-600,
    purple-700: $purple-700,
    purple-800: $purple-800,
    purple-900: $purple-900,
    pink-100: $pink-100,
    pink-200: $pink-200,
    pink-300: $pink-300,
    pink-400: $pink-400,
    pink-500: $pink-500,
    pink-600: $pink-600,
    pink-700: $pink-700,
    pink-800: $pink-800,
    pink-900: $pink-900,
    red-100: $red-100,
    red-200: $red-200,
    red-300: $red-300,
    red-400: $red-400,
    red-500: $red-500,
    red-600: $red-600,
    red-700: $red-700,
    red-800: $red-800,
    red-900: $red-900,
    orange-100: $orange-100,
    orange-200: $orange-200,
    orange-300: $orange-300,
    orange-400: $orange-400,
    orange-500: $orange-500,
    orange-600: $orange-600,
    orange-700: $orange-700,
    orange-800: $orange-800,
    orange-900: $orange-900,
    yellow-100: $yellow-100,
    yellow-200: $yellow-200,
    yellow-300: $yellow-300,
    yellow-400: $yellow-400,
    yellow-500: $yellow-500,
    yellow-600: $yellow-600,
    yellow-700: $yellow-700,
    yellow-800: $yellow-800,
    yellow-900: $yellow-900,
    green-100: $green-100,
    green-200: $green-200,
    green-300: $green-300,
    green-400: $green-400,
    green-500: $green-500,
    green-600: $green-600,
    green-700: $green-700,
    green-800: $green-800,
    green-900: $green-900,
    teal-100: $teal-100,
    teal-200: $teal-200,
    teal-300: $teal-300,
    teal-400: $teal-400,
    teal-500: $teal-500,
    teal-600: $teal-600,
    teal-700: $teal-700,
    teal-800: $teal-800,
    teal-900: $teal-900,
    cyan-100: $cyan-100,
    cyan-200: $cyan-200,
    cyan-300: $cyan-300,
    cyan-400: $cyan-400,
    cyan-500: $cyan-500,
    cyan-600: $cyan-600,
    cyan-700: $cyan-700,
    cyan-800: $cyan-800,
    cyan-900: $cyan-900,
  ),
  $theme-colors
);

$link-color: $primary;
$nav-link-color: $link-color;

$utilities: (
  "color": (
    property: color,
    class: text,
    values:
      map-merge(
        $all-colors,
        (
          "white": $white,
          "body": $body-color,
          "muted": $text-muted,
          "black-50": rgba($black, 0.5),
          "white-50": rgba($white, 0.5),
          "reset": inherit,
        )
      ),
  ),
  "background-color": (
    property: background-color,
    class: bg,
    values:
      map-merge(
        $all-colors,
        (
          "body": $body-bg,
          "white": $white,
          "transparent": transparent,
        )
      ),
  ),
  "fill": (
    property: fill,
    class: fill,
    values: $theme-colors,
  ),
  "width": (
    property: width,
    class: w,
    values: (
      10: 10%,
      15: 15%,
      20: 20%,
      25: 25%,
      30: 30%,
      50: 50%,
      75: 75%,
      100: 100%,
      auto: auto,
    ),
  ),
);

@import "~bootstrap/scss/bootstrap";
@import "~font-awesome/scss/font-awesome";
@import "../scss/form-fixes";

html {
  font-size: 16px;
}

body {
  min-height: 100vh;
}

// Copied from the landing html, just a couple of shitty
body {
  font-family: "Poppins", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.terms-embed {
  width: 100%;
  min-height: 100vh;
}

#navbar .navbar-brand img {
  max-width: 60vw;
  height: 100%;
  object-fit: cover;
}

@media (min-width: map-get($grid-breakpoints, "sm")) {
  .w-sm-25 {
    width: 25% !important;
  }
}

@media (min-width: map-get($grid-breakpoints, "md")) {
  .w-md-25 {
    width: 25% !important;
  }
}
